import React, { useState, useEffect , useRef } from 'react';
import { Row, Col, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import Icon_DotRed from '../../image/dot_red.png';
import Icon_Public from '../../image/dot_green.png';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getShelfDetails, rowReorderShelfDetails, deleteShelf,insertShelfDetails , ItemShelf , reorderTableRowIndex} from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';
import { InputText } from 'primereact/inputtext';


const ShelfManagement = () => {
    const navigate = useNavigate();
    const userLevel = useSelector(state => state.user.userLevel);
    const [shelfDetails, setShelfDetails] = useState([]);
    const dispatch = useDispatch();
    const location = useLocation();
    const { pagesTabId ,tabName} = location.state || {};
    const [formData, setFormData] = useState('');
    const cellEditCompletePending = useRef(false)


    useEffect(() => {
        if(tabName == undefined){
            handleBackClick('PagesShelf')
        }else{
            showShelfDetails();
        }
    }, []);
   
    const handleSearch = () => {
        const keyword = formData.keyword || '';
        const columnName = formData.columnName || 'shelfTitle';
        ItemShelf(keyword, columnName , pagesTabId).then(response => {
            setShelfDetails(response.data);
        });
    }

    const handleChange = (event) => {

        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    function handleEditShelf(shelfId , pagesTabId) {
 
        navigate('/ManageShelf', {
            state: {
                shelfId: shelfId ,
                pagesTabId: pagesTabId,
                tabName: tabName
            },

        });
    }

    function handleDuplicateShelf(shelfTitle, displayType, contents, status ,pagesTabId){
        dispatch(dialogConfirmSetData({
            title: "Duplicate Shelf",
            message: " Are you certain you want to duplicate this shelf? A new shelf with the same contents will be created.",
            closeButton: "Cancel",
            confirmButton: "Confirm",
            eventConfirmButton: () => insertShelfDetails(shelfTitle, displayType, contents, status ,pagesTabId).then(response => {
                showShelfDetails();

            }),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));

    }

    function handleDeleteShelf(shelfId) {
        dispatch(dialogConfirmSetData({
            title: "Confirm Shelf Deletion",
            message: " Are you sure you want to delete this shelf?",
            closeButton: "Cancel",
            confirmButton: "Confirm",
            eventConfirmButton: () => deleteShelf(shelfId).then(response => {
                
                const newData = shelfDetails.filter(item => item.ebook_shelf_id !== shelfId);
                const shelfIds = newData.map(item => item.ebook_shelf_id);
              
                if(shelfIds.length > 0){
                    reorderItem(shelfIds);
                }else{
                    setShelfDetails(newData);
                }

            }),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));
    }

    const buttonNewShelfClick = () => {
        navigate('/ManageShelf', {
            state: {
                pagesTabId: pagesTabId ,
                tabName:tabName
            },

        });
    }



    function showShelfDetails() {
        let shelfId = '';
        getShelfDetails(shelfId , pagesTabId).then(response => {
            setShelfDetails(response.data);
            console.log(response.data);

        });
    }



    function onRowReorder(event) {
        const shelfId = event.value.map(item => item.ebook_shelf_id);
        reorderItem(shelfId);
    }


    function reorderItem(shelfId) {
        rowReorderShelfDetails(shelfId , pagesTabId).then(response => {
            setShelfDetails(response.data);
        });
    }


    const onCellEditCompleteDisplay = (event) => {
        let objectIdKey = 'ebook_shelf_id';
        let normalizedArray =  reorderTableRowIndex(event,objectIdKey ,shelfDetails );
        setShelfDetails(normalizedArray);
        const reorderItemDisplay = normalizedArray.map(item => item[objectIdKey]);//[12, 11,32,43]
        reorderItem(reorderItemDisplay);
    }
    


    const textEditorDisplayOrder = (options) => {
        return (
            <InputText
                type="number"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                //onBlur={(e) => options.editorCallback(e.target.value)} // Update on blur
                onBlur={(e) => {
                    if (e.target.value !== options.value) {
                        options.editorCallback(e.target.value);
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        options.editorCallback(e.target.value); // Update on Enter key press
                    }
                }}
                style={{ width: '100%' }} // Adjust width
            />
        );
    };



    const columns = [
        {
            field: 'display_order', 
            header: 'No', 
            render: (rowData) => (
                <p className='td-text'>
                    {rowData.display_order}
                </p>
            ) , 
            editor: (options) => textEditorDisplayOrder(options),
            onCellEditCompleteDisplay: (event) => {
                // ป้องกันการเรียกซ้ำซ้อน
                if (cellEditCompletePending.current) {
                    return;
                }
            
                // ตั้งค่าเป็น true เพื่อป้องกันการทำงานซ้ำ
                cellEditCompletePending.current = true;
            
                // ตั้งค่า timeout เพื่อป้องกันการเรียกซ้ำจากหลาย event
                setTimeout(() => {
                    // Logic สำหรับการบันทึกผลการแก้ไขเซลล์ที่นี่
                  //  console.log('Cell edited:', event);
                  onCellEditCompleteDisplay(event);
                    // ตั้งค่ากลับเป็น false เมื่อเสร็จสิ้น
                    cellEditCompletePending.current = false;
                }, 0); // ใช้ timeout สั้นๆ เพื่อให้แน่ใจว่าฟังก์ชันทำงานเพียงครั้งเดียว
            }

        },
        {
            field: 'shelf_title', header: 'Shelf Title', render: (rowData) => (
                <p className='td-text'>
                    {rowData.shelf_title}
                </p>
            )
        },
        {
            field: 'contents_id', header: 'Number of Items', render: (rowData) => (
                <p className='td-text'>
                    {rowData.contents_id.split(",").length}
                </p>
            )
        },
        {
            field: 'shelf_type', header: 'Display Type', render: (rowData) => (
                <div>
                    {rowData.shelf_type === 'books' && (
                        <p className='td-text'>
                            Book
                        </p>
                    )}
                    {rowData.shelf_type === 'categories' && (
                        <p className='td-text'>
                            Subcategories
                        </p>
                    )}
                </div>
            )
        },
        {
            field: 'shelf_active', header: 'Status', render: (rowData) => (
                <p className='td-text'>
                    {rowData.shelf_active === '1' && (
                        <React.Fragment>
                            <img src={Icon_Public} width='12px' className='str_icon' />
                            {'Active'}
                        </React.Fragment>
                    )}
                    {rowData.shelf_active === '0' && (
                        <React.Fragment>
                            <img src={Icon_DotRed} width='12px' className='str_icon' />
                            {'Inactive'}
                        </React.Fragment>
                    )}
                </p>
            )
        },
        {
            field: 'Action', header: 'Action', render: (rowData) => (
                <div>
                    <a onClick={() => handleDuplicateShelf(rowData.shelf_title , rowData.shelf_type , rowData.contents_id , rowData.shelf_active, pagesTabId )}
                        className='normal-link link-button'>
                        Duplicate
                    </a>
                    {' | '}

                    <a onClick={() => handleEditShelf(rowData.ebook_shelf_id , pagesTabId)}
                        className='normal-link link-button'>
                        Edit
                    </a>
                    {' | '}
                    <a onClick={() => handleDeleteShelf(rowData.ebook_shelf_id)}
                        className='delete-link link-button'>
                        Delete
                    </a>
                </div>
            )
        }
    ];
    const dynamicColumns = columns.map((col, i) => {
        return <Column reorderable={false} reorderableColumns={false} key={col.field} columnKey={col.field} field={col.field} header={col.header} body={col.render} editor={col.editor}  onCellEditComplete={col.onCellEditCompleteDisplay} className={col.field === 'display_order' ? 'fixed-width-column' : ''} />;
    });


    const handleBackClick = (url) => {

        navigate('/'+url, {
         state: {
             pagesTabId: pagesTabId
         },
         replace: true,
 
     });
    }

    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>


                            <div className='div-row-col'>
                                <Row sm={12}>

                                    <span className='title-text'> 
                                    <div className='title-content-text' onClick={() => handleBackClick('PagesShelf')}>Pages</div>
                                    &nbsp;&nbsp;&nbsp;&nbsp; {'›'}&nbsp;&nbsp;&nbsp;&nbsp;   {tabName}
                                    
                                     </span>
                                    <p className='context-text'> Shelf descriptions </p>

                                    <Col sm={12} md={5}>  <Button onClick={() => buttonNewShelfClick()} className='button-action button-main button-orange' variant="primary" type="submit">+ New Section</Button></Col>
                                    <Col sm={12} md={7}>
                                        <InputGroup>

                                            <Form.Control style={{ width: '150px' }} className='form-control-custom' name='keyword'
                                                onKeyUp={(e) => {
                                                    handleChange(e);

                                                }}
                                                placeholder="Search..." aria-label="Search..." />
                                            <Form.Select name='columnName' onChange={handleChange} className='form-control-custom2' style={{ width: '20px', borderBottomRightRadius: '5px', borderTopRightRadius: '5px', fontFamily: 'Sukhumvit Set' }} >

                                                <option value="shelfTitle" >Shelf Title</option>
                                                <option value="displayType" >Display Type</option>
                                                <option value="status" >Status</option>
                                                
                                            </Form.Select>

                                            <div md={1} style={{ marginLeft: '10px' }}>

                                                <Button className='button-search button-main button-white' variant="primary" onClick={() => {
                                                    handleSearch();
                                                }} >Search</Button>


                                            </div>
                                        </InputGroup>
                                    </Col>
                                </Row>

                            </div>








                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body style={{ fontFamily: 'Sukhumvit Set' }}>


                                        <Col className='' >
                                            <main>
                                                <div className=''>
                                                    <div className=''>
                                                        <DataTable value={shelfDetails} reorderableColumns={false} reorderableRows onRowReorder={onRowReorder} className="reorder-datatable" tableStyle={{ width: '100%' }}  editMode="cell" >
                                                            <Column rowReorder className='reorder-datatable-column content-center' />
                                                            {dynamicColumns}
                                                        </DataTable>

                                                    </div>
                                                </div>

                                            </main>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                    </main>
                </Col>


            </Row>
        </div >
    );

}


export default ShelfManagement;